<template>
    <div class="relative">
        <div class="relative flex items-center">
            <input ref="search" v-model="search" type="text" name="search" class="form-control" :class="{'border-red-500':err}" @keydown.enter.prevent="onEnter" @keydown="onSearch" @keydown.down.prevent="arrowDown" @keydown.up.prevent="arrowUp" autocomplete="off">
            <span v-show="isSpinner" class="absolute right-2"><i class="icon-spinner4 animate-spin text-gray-400"></i></span>
        </div>
        <ul v-show="show" ref="dropdown" class="absolute bg-white border rounded-sm shadow-lg max-h-72 overflow-auto w-full py-2 mt-px">
            <li v-for="(area, i) in areas" :key="i" :ref="'list'+i" @click="onSelect(i)" @mouseover="onMouseOver(i)" @mouseleave="onMouseLeave" class="px-3 py-2 cursor-pointer" :class="{'bg-gray-200': rowCount == i}">
                {{area.name}}
            </li>
        </ul>
    </div>
</template>
<script>
import axios from 'axios'
import debounce from 'lodash.debounce'
export default {
    props: ['err', 'value'],
    data () {
        return {
            search: '',
            show: false,
            searchData: '',
            areas: [],
            rowCount: -1,
        }
    },
    created () {
        this.searchData = debounce (() => {
            if(this.search) {
                this.getData()
            }
        }, 500)
    },
    computed: {
        isSpinner () {
            let val = false
            if(this.search && this.areas.length == 0) {
                val = true
            }
            return val
        }
    },
    methods: {
        getData () {
            this.isLoading = true
            axios.get('/biteship/maps', {
                params: {
                    search: this.search
                }
            })
            .then(res => {
                this.show = true
                this.areas = res.data.areas
            })
        },
        onSearch (e) {
            if(e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 13 ) {
                this.rowCount = -1
                this.show = false
                this.areas = []
                this.$emit('input', '')
                this.searchData()
            }
        },
        onSelect (i) {
            this.$emit('input', this.areas[i].id)
            this.show = false
        },
        onEnter () {
            if(this.rowCount >= 0 && this.show && this.areas.length > 0) {
                this.$emit('input', this.areas[this.rowCount].id)
                this.show = false
                this.$refs.search.blur()
            }
        },
        onMouseOver (i) {
            this.rowCount = i
            this.search = this.areas[i].name
        },
        onMouseLeave () {
            this.rowCount = -1

        },
        arrowDown () {
            if(this.rowCount == -1) {
                this.$refs.dropdown.scrollTop = 0
            }
            if(this.rowCount < this.areas.length && this.areas.length > 0 && this.show) {
                const menuHeight = this.$refs.dropdown.clientHeight
                const scrollTop = this.$refs.dropdown.scrollTop
                this.rowCount = this.rowCount +1
                this.$nextTick(() => {
                    this.search = this.areas[this.rowCount].name
                    const top = this.$refs['list'+this.rowCount][0].offsetTop
                    const height = this.$refs['list'+this.rowCount][0].getBoundingClientRect()
                    if((top+height.height) > (menuHeight + scrollTop)) {
                        this.$refs.dropdown.scrollTop = (top + height.height) - menuHeight
                    }
                })
            }
            if(this.rowCount > this.areas.length-1 && this.show) {
                console.log(this.rowCount)
                this.rowCount = 0
                this.$refs.dropdown.scrollTop = 0
            }
        },
        arrowUp () {
            if(this.rowCount > 0 && this.show) {
                const menuHeight = this.$refs.dropdown.scrollTop
                this.rowCount = this.rowCount -1
                this.$nextTick(() => {
                    this.search = this.areas[this.rowCount].name
                    const top = this.$refs['list'+this.rowCount][0].offsetTop
                    if(top < menuHeight) {
                        this.$refs.dropdown.scrollTop = top
                    }
                })
            }
        },
    },
    watch: {
        value (val) {
            if(!val) {
                this.search = ''
            }
        }
    }
}
</script>