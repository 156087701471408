<template>
    <div class="bg-gray-50 border-b w-full h-9 flex items-center space-x-2 font-medium pl-5">
        <router-link v-for="(crumb , i) in crumbs" :key="i" class="flex items-center" :to="{ path: crumb.path }" exact>
            <i v-if="i == 0" class="icon-home4 mr-2"></i>{{crumb.name}}
            <i v-if="i+1 < crumbs.length" class="icon-arrow-right13 pl-2"></i>
        </router-link>  
    </div>
</template>

<script>

export default {
    name: 'Breadcrumb',
    props: {root: String},
    computed: {
        crumbs: function () {            
            var path = '', title = (this.root || 'Dashboard');

			var cs = [ { name: title, path: '/'} ]; if(!this.$route) return [];

			var r = (this.$route.path).split('/');
            if(this.$route.matched.length == 0) {
                return
            }
			var m = (this.$route.matched[0].meta.crumbs || this.$route.matched[1].meta.crumbs).split('/');

			for(var i = 1; i < r.length; i++)
			{
				var name = (m[i] || r[i]); if(r[i] == '') continue;
                var p = (r[i]); if(r[i] == '') continue; 
				title += '/'+ name;
				path  += '/'  + p;
		
				cs.push({ name: name, path: path });
			}

			window.document.title = 'ONLIE POS '+title;
            return cs;
        }
    },
}
</script>
<style scoped>
    .active {
        @apply text-gray-500 bg-gray-100 border-0 font-normal
    }
</style>