<template>
    <div>
        <modal :show="show">
            <div class=" w-3/5 p-5 bg-white rounded">
                <div class="flex justify-between items-center mb-5">
                    <div class="text-lg font-medium">CEK ONGKIR</div>
                    <button @click="$emit('close')" class="text-red-500 h-7 w-7"><i class="icon-cancel-circle2"></i></button>
                </div>
                <div class="grid grid-cols-5 gap-5 mb-5">
                    <div class="col-span-2">
                        <div class="mb-2">Asal</div>
                        <input value="Garut" type="text" class="form-control" readonly>
                    </div>
                    <div class="col-span-2">
                        <div class="mb-2">Tujuan</div>
                        <search-map ref="search" v-model="form.destination_area_id"></search-map>
                    </div>
                    <div class="col-span-1">
                        <div class="mb-2">Berat Barang (gram)</div>
                        <autonumeric v-model="form.items[0].weight" class="form-control text-right"></autonumeric>
                    </div>
                </div>
                <div class=" overflow-scroll" style="height: 550px">
                    <ul v-if="isLoading" class="space-y-2">
                         <li v-for="i in 10" :key="i" class="grid grid-cols-4 ph-item border rounded-sm items-center gap-5 py-3 px-5">
                            <div class="w-full rounded-sm bg-gray-200 h-10"></div>
                            <div class="w-full rounded-sm bg-gray-200 h-10"></div>
                            <div class="w-full rounded-sm bg-gray-200 h-10"></div>
                            <div class="w-full rounded-sm bg-gray-200 h-10"></div>
                        </li>
                    </ul>
                    <ul v-else class="space-y-2">
                        <li  v-for="(price, i) in pricing" :key="i" class="grid grid-cols-4 border rounded-sm items-center gap-5 py-3 px-5">
                            <div>
                                <img :src="'https://biteship.com/images/landing/'+price.company+'.png'" alt="" width="50">
                            </div>
                            <div>
                                <div class="text-gray-500">Nama Kurir</div>
                                <div>{{price.courier_name}} - {{price.courier_service_name}}</div>
                            </div>
                            <div>
                                <div class="text-gray-500">Durasi Pengiriman</div>
                                <div>{{price.shipment_duration_range}} Hari</div>
                            </div>
                            <div class="text-right">
                                <div class="text-gray-500">Ongkos Kirim</div>
                                <div class="font-medium text-green-500">{{Intl.NumberFormat().format(price.price)}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import axios from 'axios'
import Autonumeric from './Autonumeric.vue'
import Modal from './Modal.vue'
import SearchMap from './searchMap.vue'
export default {
    props: ['show'],
    components: {Modal, SearchMap, Autonumeric},
    data () {
        return {
            isLoading: false,
            form: {
                origin_area_id: 'IDNP9IDNC122IDND437IDZ44111',
                destination_area_id: '',
                couriers: "pos,jne,jnt,sicepat,anteraja,lion,tiki,gojek",
                items: [{
                    height: 1,
                    length: 1,
                    weight: 1000,
                    width: 1
                }]
            },
            pricing: []
        }
    },
    methods: {
        getRates () {
            this.isLoading = true
            axios.post('/biteship/rates', this.form)
            .then(res => {
                this.pricing = res.data.pricing
                this.isLoading = false
            })
        }
    },
    watch: {
        'form.destination_area_id': function(val) {
            if(val) {
                this.pricing = []
                this.getRates()
            }
            if(!val) {
                this.pricing = []
            }
        },
        show (val) {
            this.form.destination_area_id = ''
            this.pricing = []
            if(val) {
                this.$nextTick(() => {
                    this.$refs.search.$refs.search.focus()
                })
            }
        }
    }
}
</script>