import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Dashboard from '../views/dashboard/dashboard.vue'
import Login from '../views/auth/login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      crumbs: '/Dashboard'
    }
  },
  {
    path: '/transaksi',
    component: () => import('../views/transaksi/index.vue'),
    meta: {
      auth: true,
    }, 
    children: [
      {
        path: '',
        name: 'Transaksi',
        component: () => import('../views/transaksi/transaksi.vue'),
        meta: {
          auth: true,
          crumbs: '/Transaksi'
        }
      },
      {
        path: '/transaksi/new',
        name: 'New Transaksi',
        component: () => import('../views/transaksi/create.vue'),
        meta: {
          auth: true,
          crumbs: '/Transaksi/Baru'
        }
      },
      {
        path: '/transaksi/edit/:id',
        name: 'Edit Transaksi',
        component: () => import('../views/transaksi/edit.vue'),
        meta: {
          auth: true,
          crumbs: '/Transaksi/Edit'
        }
      },
      {
        path: '/transaksi/dropship',
        name: 'New Dropship',
        component: () => import('../views/transaksi/createDropship.vue'),
        meta: {
          auth: true,
          crumbs: '/Transaksi/Dropship'
        }
      },
      {
        path: '/transaksi/dropship/:id',
        name: 'Edit Dropship',
        component: () => import('../views/transaksi/editDropship.vue'),
        meta: {
          auth: true,
          crumbs: '/Transaksi/Edit dropship'
        }
      },
    ]
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customers/index.vue'),
    meta: {
      auth: true,
      crumbs: '/Customers'
    }
  },
  {
    path: '/preorder',
    component: () => import('../views/preorder/index.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'Pre Order',
        component: () => import('../views/preorder/preorder.vue'),
        meta: {
          auth: true,
          crumbs: '/Preorder'
        }
      },
      {
        path: '/preorder/create',
        name: 'Create Pre Order',
        component: () => import('../views/preorder/create.vue'),
        meta: {
          auth: true,
          crumbs: '/Preorder/Create'
        }
      }
    ]
  },
  {
    path: '/statistics',
    nam: 'Statistics',
    component: () => import('../views/statistics/index.vue'),
    meta: {
      auth: true,
      crumbs: '/Statistics'
    }
  },
  {
		path: '/login',
		component: Login,
		name: 'Login',
    meta: {
      crumbs: '/Login'
    }
	},
]


const router = new VueRouter({
  scrollBehavior () {
		return {x: 0, y: 0, behavior: 'smooth'}
	},
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	const tokenLocalStorage = localStorage.getItem('token')
	const userId = localStorage.getItem('userId')
	if(to.meta.auth && !store.getters['auth/token'] || to.meta.auth && !tokenLocalStorage || to.meta.auth && !userId) next({name: 'Login'})
	else if(!to.meta.auth && store.getters['auth/token'] && tokenLocalStorage && userId) next({name: 'Dashboard'})
	else next()
})


export default router
