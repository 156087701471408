<template>
	<div class="h-screen top-7">
		<aside class="fixed h-screen bg-gray-900 w-64 z- z-50">
			<div class="h-12 fixed bg-black left-0 w-64 z-30 flex justify-center items-center border-b border-gray-800">
				<div>
					<span class="text-green-600 text-xl font-semibold">Z</span>
					<span class="text-yellow-400 text-xl font-semibold">H</span>
					<span class="text-red-500 text-xl font-semibold">R</span>
					<span class="text-gray-200 text-xl font-semibold ml-2">ONLINE</span>
				</div>
			</div>
			<ul class="text-gray-300 pt-12 z-50">
				<li class="text-gray-500 p-5">
					<div>MAIN</div>
				</li>
				<li>
					<router-link to="/" class="flex h-11 items-center px-5 font-medium" exact>
						<div class="flex items-center">
							<i class="icon-home4"></i>
							<div class="leading-none ml-5">Dashboard</div>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="/transaksi" class="flex h-11 items-center px-5 font-medium">
						<div class="flex items-center">
							<i class="icon-barcode2"></i>
							<div class="leading-none ml-5">Transaksi</div>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="/preorder" class="flex h-11 items-center px-5 font-medium">
						<div class="flex items-center">
							<i class="icon-alarm"></i>
							<div class="leading-none ml-5">Pre Order</div>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="/customers" class="flex h-11 items-center px-5 font-medium">
						<div class="flex items-center">
							<i class="icon-people"></i>
							<div class="leading-none ml-5">Customers</div>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="/statistics" class="flex h-11 items-center px-5 font-medium">
						<div class="flex items-center">
							<i class="icon-chart"></i>
							<div class="leading-none ml-5">Statistics</div>
						</div>
					</router-link>
				</li>
				
			</ul>
		</aside>
		<div class="pl-64">
			<nav class="h-12 bg-white border-b right-0 px-5 left-20 fixed flex items-center justify-between z-20">
				<div>
					<span v-if="shop" class="text-lg font-semibold">{{shop.name}}</span>
				</div>
				<div>
					<ul>
						<li class="relative">
							<dropdown-menu :right="true">
								<a href="#" class="relative px-4 flex items-center h-12 user hover:bg-gray-200 hover:bg-opacity-50">
									<div class="h-8 w-8 rounded-full bg-red-500 flex items-center justify-center text-red-50">
										<div v-if="user">{{user.name[0]}}</div>
									</div>
									<div class="ml-2"><span v-if="user">{{user.name}}</span></div>
									<i class="icon-arrow-down22 ml-2"></i>
								</a>
								<template #dropdown>
									<div class=" w-48 ">
										<router-link to="/my-account" class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"><i class="icon-user mr-4"></i>Akun Saya</router-link>
										<a @click="signOut" href="#" class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"><i class="icon-switch2 mr-4"></i>Keluar</a>
									</div>
								</template>
							</dropdown-menu>
						</li>
					</ul>
				</div>
			</nav>
			<div class="pt-12 pb-5 relative">
				<breadcrumb></breadcrumb>
				<transition name="fade" mode="out-in">
					<router-view />
				</transition>
			</div>
		</div>
		<modal :show="modalBarang">
			<div class=" w-3/4 bg-white rounded overflow-hidden relative">
				<div class="flex items-center justify-between px-5 py-3 border-b">
					<div>CEK STOK BARANG</div>
					<div>
						<button @click="modalBarang=false"><i class="icon-cross2"></i></button>
					</div>
				</div>
				<div class="p-5">
					<input ref="search" v-model="search" type="text" name="search" class="form-control" placeholder="CARI NAMA BARANG">
				</div>
				<div style="height: 500px">
					<table class="w-full">
						<thead>
							<th class="pl-5 py-3 pr-3 bg-red-500 text-red-50" style="width: 10%">SKU</th>
							<th class="p-3 bg-red-500 text-red-50" style="width: 60%">NAMA BARANG</th>
							<th class="p-3 bg-red-500 text-red-50" style="width: 20%">TOKO</th>
							<th class="pl-3 py-3 pr-5 bg-red-500 text-red-50 text-right" style="width: 10%">STOK</th>
						</thead>
						<tbody v-if="isLoading" class="divide-y">
							<tr v-for="i in 10" :key="i">
								<td class="pl-5 py-3 pr-3 ph-item">
									<div class="h-4 w-16 bg-gray-200"></div>
								</td>
								<td class="p-3 ph-item">
									<div class="h-4 w-72 bg-gray-200"></div>
								</td>
								<td class="p-3 ph-item">
									<div class="h-4 w-32 bg-gray-200"></div>
								</td>
								<td class="p-3 ph-item">
									<div class="h-4 w-32 bg-gray-200"></div>
								</td>
							</tr>
						</tbody>
						<tbody v-else class="divide-y">
							<tr v-for="(item, i) in items" :key="i">
								<td class="pl-5 py-3 pr-3">{{item.sku}}</td>
								<td class="p-3">{{item.name}}</td>
								<td class="p-3">{{item.shop}}</td>
								<td class="pl-3 py-3 pr-5 text-right">{{item.qty}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-right py-3 pr-5">
					<button @click="modalBarang=false" class="h-9 px-5 text-blue-500 hover:text-blue-600 border rounded-sm">TUTUP</button>
				</div>
			</div>
		</modal>
		<div class="fixed right-3 bottom-0">
			<button @click="modalOngkir=true" class="h-9 bg-blue-500 px-5 rounded-t-lg text-blue-50 hover:bg-blue-600 mr-1">CEK ONGKIR</button>
			<button @click="modalBarang=true" class="h-9 bg-red-500 px-5 rounded-t-lg text-red-50 hover:bg-red-600">CEK STOK BARANG</button>
		</div>
		<cek-ongkir :show="modalOngkir" @close="modalOngkir=false"></cek-ongkir>
	</div>
</template>

<script>
import DropdownMenu from './components/DropdownMenu.vue'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumb from './components/Breadcrumb.vue'
import Modal from './components/Modal.vue'
import axios from 'axios'
import debounce from 'lodash.debounce'
import CekOngkir from './components/cekOngkir.vue'
export default {
	components: {DropdownMenu, Breadcrumb, Modal, CekOngkir},
	data () {
		return {
			isLoading: false,
			search: '',
			searchData: '',
			modalBarang: false,
			modalOngkir: false,
			items: []
		}
	},
	created () {
		this.searchData = debounce(() => {
			this.getBarang()
		}, 400)
	},
	computed: {
		...mapGetters({
			shop: 'auth/shop',
			user: 'auth/user'
		})
	},

	methods: {
		getBarang () {
			this.isLoading = true
			axios.get('/inventory/stock/barang/online', {
				params: {
					search: this.search
				}
			})
			.then(res => {
				this.isLoading = false
				this.items = res.data
			})
		},
		...mapActions({
			logout: 'auth/logout'
		}),
		async signOut () {
			this.isLoading = true
			const token = localStorage.getItem('refreshToken')
			await this.logout(token)
			.then(() => {
				this.$router.push('/login')
				this.isLoading = false
			})
		}
	},
	watch: {
		search () {
			this.searchData()
		},
		modalBarang (val) {
			if(val) {
				this.$nextTick(() => {
					this.$refs.search.focus()
				})
			}
		}
	}
}
</script>

<style>
	.active {
		@apply bg-teal-600 text-teal-50
	}
	.err .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
        border-color: #ef4444 !important;
    }
</style>