<template>
    <div class="m-5">
        <div class="flex items-center space-x-5 mb-5">
            <div v-for="(user, i) in users" :key="i" class="bg-white border rounded-sm p-5 w-full flex items-center">
                <div class="border rounded-full h-12 w-12 flex items-center justify-center">
                    <i class="icon-user icon-2x text-gray-400"></i>
                </div>
                <div class="text-right ml-auto">
                    <div class="text-base font-semibold">{{Intl.NumberFormat().format(user.total)}}</div>
                    <div>{{user.user}}</div>
                </div>
            </div>
            <div class="bg-green-500 text-green-50 border rounded-sm p-5 w-full flex items-center">
                <div class="">
                    <i class="icon-coin-dollar icon-3x text-green-200"></i>
                </div>
                <div class="text-right ml-auto">
                    <div class="text-base font-semibold">{{Intl.NumberFormat().format(total)}}</div>
                    <div>TOTAL HARI INI</div>
                </div>
            </div>
        </div>
        <div class="border rounded-sm grid grid-cols-5">
            <div class="col-span-4 p-3 bg-white border-r">
                <canvas id="chartshop"></canvas>
            </div>
            <div class="col-span-1 bg-white p-3">
                <ul class="space-y-2">
                    <li v-for="(market, i) in stats" :key="i" class="p-3 rounded-sm text-right border flex items-center">
                        <div class="rounded overflow-hidden">
                            <img :src="imgURL+'/'+market.logo" alt="" width="35">
                        </div>
                        <div class="ml-auto text-right">
                            <div class="text-base font-medium">Rp. {{Intl.NumberFormat().format(market.total)}}</div>
                            <div class="text-xs text-gray-500 uppercase">{{market.label}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import axios from 'axios'
export default {
    data () {
        return {
            users: [],
            imgURL: process.env.VUE_APP_ROOT_API,
            labels: [],
            stats: []
        }
    },
    mounted () {
        this.getData()
        let app = this
        const ctxShop = document.getElementById('chartshop')
        window.market = new Chart(ctxShop, {
            type: 'line',
            data: {
                users: [],
                labels: app.labels,
                datasets: app.stats
            },
            options: {
                plugins: {
                    legend: {
						display: false,
					},
                }
            }
        });
    },
    computed: {
        total () {
            let sum = 0
            for (let i = 0; i < this.users.length; i++) {
                const el = this.users[i];
                sum += el.total
            }
            return sum
        }
    },
    methods: {
        getData () {
            axios.get('/online/dashboard')
            .then(res => {
                this.users = res.data.user
                const datas = res.data.markets
                let minDay = 7
                for(let i = 0; i < 7; i++) {
                    minDay = minDay - 1
                    const lb =  fd(minDay)
                    this.labels.push(lb)
                }
                for (let i = 0; i < datas.length; i++) {
                    const shop = datas[i]
                    let color = '#4B5563'
                    if(shop.market.toLowerCase() == 'whatsapp') {
                        color = '#3de25a'
                    }
                    if(shop.market.toLowerCase() == 'tokopedia') {
                        color = '#83c467'
                    }
                    if(shop.market.toLowerCase() == 'shopee') {
                        color = '#f0582c'
                    }
                    if(shop.market.toLowerCase() == 'lazada') {
                        color = '#1851ce'
                    }
                    if(shop.market.toLowerCase() == 'lazada') {
                        color = '#1851ce'
                    }
                    if(shop.market.toLowerCase() == 'instagram') {
                        color = '#d72b88'
                    }
                    if(shop.market.toLowerCase() == 'tiktok') {
                        color = '#010101'
                    }
                    this.stats.push({label: shop.market, logo: shop.logo, total: shop.total, data: [], fill: false, borderColor: color, backgroundColor: color, pointStyle: 'circle', pointRadius: 5})
                    for (let a = 0; a < this.labels.length; a++) {
                        const lb = this.labels[a]
                        if(ds (shop, lb)) {
                            this.stats[i].data.push(ds(shop, lb))
                        } else {
                            this.stats[i].data.push(0)
                        }
                    }
                }
                function ds (shop, lb) {
                    for(let b = 0; b < shop.data.length; b++) {
                        const dt = shop.data[b]
                        if(dt.tanggal == lb) {
                            return dt.total
                        } 
                    }
                    }
                function fd (e) {
                    let d = new Date()
                    d.setDate(d.getDate() - e)
                    let dd = d.getDate()
                    let mm = d.getMonth() + 1
                    let yy = d.getFullYear()
                    dd = checkTime(dd)
                    mm =checkTime(mm)
                    return `${yy}-${mm}-${dd}`
                }
                function checkTime (i) {
                    if(i < 10) {
                        i = `0${i}`
                    }
                    return i
                }
                window.market.update()
            })
        }
    }
}
</script>